import { Controller } from "stimulus";
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import AwsS3 from '@uppy/aws-s3';

export default class extends Controller {

  connect() {
    this.load()
  }

  load() {
    var token = $('meta[name="csrf-token"]').attr('content');

    const uppy = Uppy({
      debug: true,
      restrictions: {
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ['.mov', '.MOV']
      },
    })
    .use(Dashboard, {
      target: '#uppy-uploader',
      inline: true,
      width: 400,
      height: 200,
      thumbnailWidth: 280,
      showProgressDetails: true,
      hideUploadButton: false,
      note: "Accepts .mov",
      disableInformer: false,
      disableThumbnailGenerator: false,
      proudlyDisplayPoweredByUppy: false,
      metaFields: [ { id: 'label', name: 'Name'} ],
      locale: {
        strings: {
          dropPaste: 'Drop file here, or %{browse}',
        }
      }
    })
    .use(AwsS3, {
      limit: 1,
      // timeout: 100000,
      getUploadParameters (file) {
        return fetch('/admin/presign', {
          method: 'post',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRF-Token': token
          },
          credentials: 'same-origin',
          body: JSON.stringify({
            filename: file.name,
            contentType: file.type
          })
        }).then((response) => {
          // Parse the JSON response.
          return response.json()
        }).then((data) => {
          // Return an object in the correct shape.
          return {
            method: data.method,
            url: data.url,
            fields: data.fields
          }
        })
      }
    })
    // Create Video object on upload success
    uppy.on('upload-success', (file, resp, uploadURL) => {
      console.log(file);
      var originalFilename = file.name.split(".MOV")[0];
      var awsFilename = (originalFilename + uploadURL.split(originalFilename)[1]).split(".MOV")[0];
      var label;
      if (file.meta.label) {
        label = file.meta.label
      } else {
        label = originalFilename
      }
      fetch('/videos', {
        method: 'post',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': token
        },
        credentials: 'same-origin',
        body: JSON.stringify({
          video: {
            name: label,
            filename: awsFilename,
            url: uploadURL
          }
        })
      })
    })
  }
}

