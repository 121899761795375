import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = [ "name", "button" ]

  connect() {
    this.validate();
  }

  validate() {
    if (this.nameTarget.value == '') {
      this.buttonTarget.setAttribute("disabled", "disabled");
    } else {
      this.buttonTarget.removeAttribute("disabled");
    }
  }
}

