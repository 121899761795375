import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["videoElement"]

  connect() {
    this.load()
  }

  load() {
    var player = videojs(this.videoElementTarget, {
      controls: true,
      autoplay: false,
      preload: 'auto',
      height: 432,
      width: 640
    })

    player.ready(function(){
      this.play();
    });    
  }

}
