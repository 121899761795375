import './Cata-1.jpg';
import './Cata-3.jpg';
import './Cata-7.jpg';
import './Cata-10.jpg';
import './Cata-12.jpg';
import './Cata-15.jpg';
import './clarinet_supplies.jpg';
import './teaching.jpg';
import './mouthpieces.jpg';

// UI
import './favicon.ico';
import './logo-footer.png';
import './logo-header.png';
import './top-divider.png';

