import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
Rails.start()
Turbolinks.start()
ActiveStorage.start()

// import all image files in a folder:
require.context('../images', true)

import '../styles/application.scss';
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import Flickity from 'flickity/dist/flickity.pkgd.min.js';
import 'bootstrap/dist/js/bootstrap';
import './direct_uploads';

const application = Application.start();
const context = require.context("./controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

document.addEventListener("turbolinks:load", function() {
  // Flickity carousel
  var elem = document.querySelector('.main-carousel');
  if (elem) {
    var flickity = new Flickity(elem, {
      wrapAround: true,
      adaptiveHeight: true,
      autoPlay: 6000,
      selectedAttraction: 0.025,
      friction: 0.8,
      imagesLoaded: true
    });
  }

  // Navbar: active nav-links
  var pathname = window.location.pathname;
  $('a[href^="'+ pathname +'"]').addClass('active').parent('.nav-item').siblings().find('a').removeClass('active');
});

